import superagent from 'superagent'

import { store } from 'store'
import AppConfig from 'config'

export const graphqlApi = {
  post,
  postAuditLogs,
  postDeliveryDateExceptions,
  postDeliveryOptions,
  postDeliveryExceptionsPermissions,
  postDeliveryTracking,
  postDeliveryPromises,
  postRoles,
  postShipAddress,
  postShipmentOptions,
  postShippingLabels,
  postTrackedShippers,
  postFailedOrderMovements,
  handleGraphqlErrors,
  buildAuditLogsQuery,
  buildDeliveryDateExceptionsQuery,
  buildDeliveryOptionsQuery,
  buildDeliveryTrackingQuery,
  buildDeliveryPromisesQuery,
  buildShippingLabelsQuery,
  buildShipmentOptionsQuery,
  buildTrackedShippersQuery,
}

function handleGraphqlErrors(response) {
  if (response.body.errors) {
    const messages = response.body.errors.map(error => error.message)
    throw new Error(`Invalid Graphql: ${messages.join(', ')}`)
  }
}

function postRoles() {
  const query = '{roles {title}}'
  return post(query)
}

function buildDeliveryOptionsQuery(country, brand, zipcode = null) {
  let argString = ''

  argString = country ? argString.concat(`country:${country.toUpperCase()},`) : argString
  argString = brand ? argString.concat(`brand:${brand.toUpperCase()},`) : argString

  if (zipcode) {
    argString = argString.concat(`zipcode:"${zipcode}",`)
  }

  return (
    `{deliveryOptions(${argString}) {` +
    'region ' +
    'suburb ' +
    'from ' +
    'to ' +
    'shipper: shipperName ' +
    'deliveryDay: dayOfWeek ' +
    'wday: dayOfWeekNumber ' +
    'productionSite ' +
    'transitTime ' +
    'zipCount: numOfDeliveryZips ' +
    'priceTierIdentifier ' +
    'pausedFrom ' +
    'pausedUntil ' +
    '}}'
  )
}

function buildDeliveryDateExceptionsQuery(country, brand, date, orderType) {
  let argString = ''

  argString = country ? argString.concat(`country:${country.toUpperCase()},`) : argString
  argString = brand ? argString.concat(`brand:${brand.toUpperCase()},`) : argString
  argString = date ? argString.concat(`date:"${date}",`) : argString
  argString = orderType ? argString.concat(`orderType:${orderType.toUpperCase()},`) : argString

  return (
    `{deliveryDateOptions(${argString}) {` +
    'region ' +
    'suburb ' +
    'transitTime ' +
    'productionSite ' +
    'cutoffDays ' +
    'regularCutoffDate ' +
    'regularShipmentDate ' +
    'regularDeliveryDate ' +
    'regularSlot {shipperName, from, to, priceTierIdentifier} ' +
    'exceptionalCutoffDate ' +
    'exceptionalShipmentDate ' +
    'exceptionalDeliveryDate ' +
    'exceptionalSlot {shipperName, from, to, priceTierIdentifier} ' +
    'exceptionType ' +
    '}}'
  )
}

function buildDeliveryExceptionsPermissionsQuery(country, regularDeliveryDate) {
  let argString = ''

  argString = country ? argString.concat(`country:${country.toUpperCase()},`) : argString
  argString = regularDeliveryDate
    ? argString.concat(`regularDeliveryDate:"${regularDeliveryDate}",`)
    : argString

  return `{deliveryExceptionsPermissions(${argString}) {` + 'canCreate ' + 'canDestroy ' + '}}'
}

function buildShippingLabelsQuery(data) {
  let argString = ''

  argString = argString.concat(
    `countries:${buildCountry(data.continent, data.country, data.countries)},`,
  )
  argString = data.brand ? argString.concat(`brands:${buildBrand(data.brand)},`) : argString
  argString = data.deliveryDateRange
    ? argString.concat(`deliveryDateRange:${buildDeliveryDate(data.deliveryDateRange)},`)
    : argString

  if (data.missingLabels) {
    argString = argString.concat(`missingLabels:${data.missingLabels},`)

    return (
      `{shippingLabels(${argString}) {` +
      'shipmentNumber ' +
      'shipmentDate ' +
      'deliveryDate ' +
      'shipper ' +
      'status ' +
      'statusData ' +
      '}}'
    )
  }

  if (data.pendingLabels) {
    return buildPendingLabelsQuery(data, argString)
  }

  return (
    `{shippingLabels(${argString}) {` +
    'shipmentNumber ' +
    'shipmentDate ' +
    'deliveryDate ' +
    'shipper ' +
    'trackingNumber ' +
    '}}'
  )
}

function buildDeliveryTrackingQuery(orderNumber, shipmentNumber, trackingNumber) {
  const argString = `orderNumber:"${orderNumber}",shipmentNumber:"${shipmentNumber}",trackingNumber:"${trackingNumber}"`

  return (
    `{ deliveryTracking(${argString}) {` +
    'orderNumber ' +
    'shipmentNumber ' +
    'trackingNumber ' +
    'shipperName ' +
    'shipmentCountry ' +
    'brand ' +
    'shipmentTrackingActivities {' +
    'internalStatus ' +
    'internalStatusDetails ' +
    'shipperStatus ' +
    'timestamp ' +
    'country ' +
    'state ' +
    'city ' +
    'zipcode ' +
    'address ' +
    'receivedBy ' +
    'boxPlacedLocation ' +
    'otherDeliveryInfo ' +
    '}}}'
  )
}

function buildShipmentOptionsQuery(params) {
  let argString = ''

  const {
    country,
    brand,
    zipcode,
    regions,
    productionSite,
    shipper,
    wdays,
    first,
    after,
    last,
    before,
  } = params

  argString = country ? argString.concat(`country:${country.toUpperCase()},`) : argString
  argString = brand ? argString.concat(`brand:${brand.toUpperCase()},`) : argString
  argString = zipcode ? argString.concat(`zipcode:"${zipcode}",`) : argString
  argString = regions ? argString.concat(`regions:${JSON.stringify(regions)},`) : argString
  argString = productionSite ? argString.concat(`production_site:"${productionSite}",`) : argString
  argString = shipper ? argString.concat(`shipper:"${shipper}",`) : argString
  argString = wdays ? argString.concat(`wdays:${JSON.stringify(wdays)},`) : argString
  argString = first ? argString.concat(`first:${first},`) : argString
  argString = after ? argString.concat(`after:"${after}",`) : argString
  argString = last ? argString.concat(`last:${last},`) : argString
  argString = before ? argString.concat(`before:"${before}",`) : argString

  return (
    `{shipmentOptions(${argString}) {` +
    'pageInfo {' +
    'startCursor ' +
    'endCursor ' +
    'hasNextPage ' +
    'hasPreviousPage ' +
    '}' +
    'edges {' +
    'node {' +
    'id ' +
    'region ' +
    'zipcode ' +
    'wday ' +
    'from ' +
    'to ' +
    'startDate ' +
    'endDate ' +
    'shipperName ' +
    'productionSite ' +
    'transitTime ' +
    'priority ' +
    '}}}}'
  )
}

const deliveryPromisesArgString = params => {
  let argString = ''
  const { country, brand, zipcode, regions, wdays, first, after, last, before } = params

  argString = country ? argString.concat(`country:${country.toUpperCase()},`) : argString
  argString = brand ? argString.concat(`brand:${brand.toUpperCase()},`) : argString
  argString = zipcode ? argString.concat(`zipcode:"${zipcode}",`) : argString
  argString = regions ? argString.concat(`regions:${JSON.stringify(regions)},`) : argString
  argString = wdays ? argString.concat(`wdays:${JSON.stringify(wdays)},`) : argString
  argString = first ? argString.concat(`first:${first},`) : argString
  argString = after ? argString.concat(`after:"${after}",`) : argString
  argString = last ? argString.concat(`last:${last},`) : argString
  argString = before ? argString.concat(`before:"${before}",`) : argString

  return argString
}

function buildDeliveryPromisesQuery(params) {
  return (
    `{deliveryPromises(${deliveryPromisesArgString(params)}) {` +
    'pageInfo {' +
    'startCursor ' +
    'endCursor ' +
    'hasNextPage ' +
    'hasPreviousPage ' +
    '}' +
    'edges {' +
    'node {' +
    'brand ' +
    'from ' +
    'to ' +
    'startDate ' +
    'endDate ' +
    'region ' +
    'wday ' +
    'zipcode ' +
    'shipmentOptions { ' +
    'id ' +
    'region ' +
    'shipperName ' +
    'priority ' +
    'transitTime ' +
    'productionSite ' +
    'wday ' +
    'from ' +
    'to ' +
    'startDate ' +
    'endDate ' +
    'zipcode ' +
    '}}}}}'
  )
}

function buildPendingLabelsQuery(data, argString) {
  argString = argString.concat(`pendingLabels:${data.pendingLabels},`)

  return (
    `{shippingLabels(${argString}) {` +
    'shipmentNumber ' +
    'shipmentDate ' +
    'deliveryDate ' +
    'shipper ' +
    'status ' +
    'statusData ' +
    '}}'
  )
}

function buildCountry(continent, country, countries) {
  // TO-DO: move data in AppConfig to Logistics

  if (countries) {
    return `[${countries.map(country => country.toUpperCase())}]`
  }

  if (country.toUpperCase() === 'ALL') {
    let countriesByContinent = AppConfig.countries
      .filter(country => country.continent === continent)
      .map(country => country.name.toUpperCase())

    return `[${countriesByContinent}]`
  } else {
    return country.toUpperCase()
  }
}

function buildBrand(brand) {
  // TO-DO: move data in AppConfig to Logistics

  if (brand.toUpperCase() === 'ALL') {
    const appBrands = AppConfig.brands.map(brand => brand.toUpperCase())
    return `[${appBrands}]`
  } else if (brand.toUpperCase() === 'ALL_WITH_EXTRA_BRANDS') {
    const appBrandsWithExtraBrands = AppConfig.brands
      .concat(AppConfig.extraBrands)
      .map(brand => brand.toUpperCase())
    return `[${appBrandsWithExtraBrands}]`
  } else {
    return brand.toUpperCase()
  }
}

function buildDeliveryDate(dates) {
  return `["${dates[0]}", "${dates[1]}"]`
}

function buildAuditLogsQuery() {
  return '{ auditLogs { timestamp email action payload }}'
}

function buildTrackedShippersQuery() {
  return '{ trackedShippers { ' + 'name ' + 'country ' + 'brand ' + '}}'
}

function postShippingLabels(data, featureFlags) {
  const query = buildShippingLabelsQuery(data)

  return post(query, featureFlags)
}

function postDeliveryTracking(data) {
  const query = buildDeliveryTrackingQuery(
    data.orderNumber,
    data.shipmentNumber,
    data.trackingNumber,
  )

  return post(query)
}

function postDeliveryDateExceptions(data) {
  const query = buildDeliveryDateExceptionsQuery(
    data.country,
    data.brand,
    data.regularDeliveryDate,
    data.orderType,
  )

  return post(query)
}

function postDeliveryExceptionsPermissions(data) {
  const query = buildDeliveryExceptionsPermissionsQuery(
    data.country,
    data.regularDeliveryDate,
    data.orderType,
  )

  return post(query)
}

function postDeliveryOptions(data) {
  const query = buildDeliveryOptionsQuery(data.country, data.brand, data.zipcode)

  return post(query)
}

function postAuditLogs() {
  const query = buildAuditLogsQuery()

  return post(query)
}

function postShipmentOptions(data) {
  const query = buildShipmentOptionsQuery(data)

  return post(query)
}

function postShipAddress({ shipmentNumber }) {
  const query = `
    {
      shipAddress(shipmentNumber: "${shipmentNumber}") {
        id
        shipmentId
        line1
        line2
        city
        state
        zipcode
        firstname
        lastname
        phone
        country
        stateAbbreviation
        deliveryInstructions
        company
        houseNumber
      }
    }`

  return post(query)
}

function postDeliveryPromises(data) {
  const query = buildDeliveryPromisesQuery(data)

  return post(query)
}

function postTrackedShippers() {
  const query = buildTrackedShippersQuery()

  return post(query)
}

function postFailedOrderMovements(data) {
  let argString = ''
  argString = argString.concat(`country:${data.country.toUpperCase()},`)
  argString = argString.concat(`movementDate:"${data.movementDate}"`)

  const query = `{failedOrderMovements(${argString})}`

  return post(query)
}

function getFeatureFlags() {
  const flags = store.getState()['featureFlags']
  const flagString = Object.keys(flags)

  if (flagString.length) {
    return flagString.join(',')
  }
}

function post(query, flags) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/graphql`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const featureFlags = flags || getFeatureFlags()
  const body = { query: query, feature_flags: featureFlags }

  return superagent
    .post(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
