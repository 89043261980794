import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppConfig from 'config'
import Filter from '../filter/filter'
import { clearData, setGlobalFilter } from 'actions/index'

const ALL_BRANDS_TEXT = 'All Brands'
const ALL_BRANDS = 'all'
const ALL_WITH_EXTRA_BRANDS = 'all_with_extra_brands'

export const BrandsFilter = props => {
  const handleChange = e => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('brands', e.target.value))
  }

  return (
    <Filter
      name='brands'
      displayUpcase
      onChange={handleChange}
      data={
        props.includeExtraBrands ? AppConfig.brands.concat(AppConfig.extraBrands) : AppConfig.brands
      }
      selectedValue={props.brands}
      labelText='Brand'
      defaultText={ALL_BRANDS_TEXT}
      defaultValue={props.includeExtraBrands ? ALL_WITH_EXTRA_BRANDS : ALL_BRANDS}
    />
  )
}

function mapStateToProps(state) {
  return {
    brands: state.globalFilters.brands,
  }
}

BrandsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  brands: PropTypes.array,
  includeExtraBrands: PropTypes.bool,
}

const connectedBrandsFilter = connect(mapStateToProps)(BrandsFilter)
export default connectedBrandsFilter
