const AppConfig = {
  brands: ['ms', 'dn', 'bm'],
  extraBrands: ['oob'],
  continents: ['europe', 'north america', 'oceania'],

  countries: [
    { continent: 'europe', name: 'at' },
    { continent: 'oceania', name: 'au' },
    { continent: 'europe', name: 'be' },
    { continent: 'europe', name: 'de' },
    { continent: 'europe', name: 'ms' },
    { continent: 'europe', name: 'nl' },
    { continent: 'north america', name: 'us' },
  ],
  geographicalDomains: {
    at: 'at',
    au: 'com.au',
    be: 'be',
    de: 'de',
    ms: 'ms',
    nl: 'nl',
    us: 'com',
  },
  orderTypes: ['regular', 'special'],
  productionSites: ['CA1', 'MEL1', 'NWG', 'NYC1', 'SYD1', 'TEX1', 'BMD1'],
  statuses: ['not_arrived_at_carrier'],
}

export default AppConfig
